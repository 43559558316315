import * as React from 'react'

import { Helmet } from 'react-helmet'

import { LinkCustom } from '../components'
import { BannerPage } from '../components/BannerPage'
import { Seo } from '../components/Seo'
// import { Seo } from '../components/Seo'

const BeAGuest: React.FC = () => {
  const title = 'Be a guest'
  const description =
    'Are you interested on being interviewed for the Podcast "World Web Stories"? The only podcast that travels the world to interview web enthusiasts!'

  return (
    <>
      <Seo title={title} description={description} pathname="be-a-guest/" isLanding />
      <Helmet>
        <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js" defer></script>
      </Helmet>
      <BannerPage
        title={title}
        description={description}
        component={
          <>
            Feel free to check the{' '}
            <LinkCustom to="/faq/" className="underline">
              FAQ if you have any doubts or questions.
            </LinkCustom>
          </>
        }
      />
      <div className="pt-2 overflow-hidden">
        <iframe
          className="airtable-embed airtable-dynamic-height overflow-hidden"
          src="https://airtable.com/embed/shrYoVwOErfhv1PYH"
          frameBorder="0"
          width="100%"
          height="3155"
          title="Airtable form to fill to be a guest on World Web Stories podcast"
        ></iframe>
      </div>
    </>
  )
}

export default BeAGuest
